body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.colored-toast.swal2-icon-success {
  background-color: #a5dc86 !important;
}

.colored-toast.swal2-icon-error {
  background-color: #f27474 !important;
}

.colored-toast.swal2-icon-warning {
  background-color: #f8bb86 !important;
}

.colored-toast.swal2-icon-info {
  background-color: #3fc3ee !important;
}

.colored-toast.swal2-icon-question {
  background-color: #87adbd !important;
}

.colored-toast .swal2-title {
  color: white;
}

.colored-toast .swal2-close {
  color: white;
}

.colored-toast .swal2-html-container {
  color: white;
}

.css-tdnrhj tr:nth-of-type(odd) td {
  background: #f3f3f3 !important;
}

.bg-white {
  background: white !important;
}

.circle-orange {
  background: #f06e26 !important;
  color: white !important;
  border: 15px solid #fff !important;
  width: 250px !important;
  height: 250px !important;
  border-radius: 50% !important;
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
}

.circle-red {
  background: #ef3a3a !important;
  color: white !important;
  border: 15px solid #fff !important;
  width: 250px !important;
  height: 250px !important;
  border-radius: 50% !important;
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
}


.circle-black {
  background: #000000 !important;
  color: white !important;
  border: 15px solid #fff !important;
  width: 250px !important;
  height: 250px !important;
  border-radius: 50% !important;
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
}

.circle-orange2x {
  background: #f06e26 !important;
  color: white !important;
  border: 15px solid #fff !important;
  width: 300px !important;
  height: 300px !important;
  border-radius: 50% !important;
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
}

.css-1cqh9jq {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column-reverse !important;
  gap: 3px;
  text-transform: uppercase;
  width: 150px;
}

.css-14iyyou {
  font-size: 12px !important;
}


._3gEzx{
  white-space: nowrap;
}

.form-control{
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
}

.w-100{
  width: 100% !important;
}

.MuiPieArcLabel-root{
  font-size: 12px !important;
  color: white !important;
  fill: white !important;
}

.border{
  border: 1px solid #dee2e6 !important;
}